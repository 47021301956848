<template>
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Customer Servers</h4>
          <p class="card-text"></p>
          <div class="row">
            <div class="col-sm-12">
              <div class="card mt-2 mt-4" v-for="customerServer in customerServers" :key="customerServer">
                <div class="card-body">
                  <p class="card-title">
                    <!-- Server Name: {{ customerServer.domain_name }}  -->
                    Server Name: {{ customerServer.server.name }}
                    <span class="float-right">
                      <button @click="editCustomerServer(customerServer)" class="btn btn-primary btn-sm mr-1">Edit</button>
                      <button class="btn btn-danger btn-sm mr-1" @click="deleteCustomerServer(customerServer)">Delete</button>
                    </span>
                  </p>
                  <p class="card-text">
                    IP Address: <small> {{ customerServer.ip_address }}</small><br>
                    Domain Name: <small> {{ customerServer.domain_name }}</small><br>
                    Product Name: <small> {{ customerServer.product_name }}</small><br>
                    Repository link: <small> {{ customerServer.repo }}</small><br>
                    Document Root: <small> {{ customerServer.doc_root }}</small><br>
                    Database Name: <small> {{ customerServer.db_name }}</small><br>
                    Database User: <small> {{ customerServer.db_user }}</small><br>
                    Database Password: <small v-bind:class="{text_As_Pswrd: disc_text}" id="db_password" ref="db_password"> {{ customerServer.db_password }}</small>
                    <i v-on:click="showPasword" id="eyeIcon" class="fa fa-eye zoom ml-2" style="cursor: pointer;" aria-hidden="true"></i><br>
                    Phpmyadmin: <small> {{ customerServer.php_my_admin }}</small><br>
                    Notes: <small> {{ customerServer.notes }}</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card">
        <form @submit="formSubmit" class="user">
          <div class="card-body">
            <div class="row">
              <div class="col-md-10">
                <h4 class="card-title">{{ customerServerFormTitle }}</h4>
              </div>
              <div class="col-md-2">
                <button v-show="newCustomerServer.server_id" type="button" @click="resetForm()" class="btn float-right btn-warning btn-circle btn-sm"><i class="fa fa-times" aria-hidden="true"></i></button>
              </div>
            </div>
            <p class="card-text"></p>

            <label for="">Server Name</label>
            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="">
                  <select v-model="newCustomerServer.server_id" class="form-control form-control-sm">
                    <option selected :value="undefined">--Select--</option>
                    <option :value="server.id" v-for="server in servers" :key="server">
                      {{ server.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <input type="text" v-model="newCustomerServer.ip_address" placeholder="IP address" class="form-control form-control-sm">
              </div>
              <div class="col-md-6 mb-3">
                <input type="text" v-model="newCustomerServer.domain_name" placeholder="Domain name" class="form-control form-control-sm">
              </div>
              <div class="col-md-6 mb-3">
                <input type="text" v-model="newCustomerServer.product_name" placeholder="Product name" class="form-control form-control-sm">
              </div>
              <div class="col-md-8 mb-3">
                <input type="text" v-model="newCustomerServer.repo" placeholder="Repository link" class="form-control form-control-sm">
              </div>
              <div class="col-md-4">
                <input type="text" v-model="newCustomerServer.doc_root" placeholder="Document root" class="form-control form-control-sm">
              </div>  
              <div class="col-md-3 mb-3">
                <input type="text" v-model="newCustomerServer.db_name" placeholder="DB Name" class="form-control form-control-sm">
              </div>
              <div class="col-md-3 mb-3">
                <input type="text" v-model="newCustomerServer.db_user" placeholder="DB User" class="form-control form-control-sm">
              </div>
              <div class="col-md-3 mb-3">
                <input type="text" v-model="newCustomerServer.db_password" placeholder="DB Password" class="form-control form-control-sm">
              </div>
              <div class="col-md-3 mb-3">
                <input type="text" v-model="newCustomerServer.php_my_admin" placeholder="Phpmyadmin" class="form-control form-control-sm">
              </div>
              <div class="col-md-12 mb-3">
                <label for="">Notes</label>
                <textarea
                  v-model="newCustomerServer.notes"
                  class="form-control"
                  cols="20"
                  rows="2"
                ></textarea>
              </div>
            </div>
            <button :disabled="!newCustomerServer.server_id" class="btn btn-primary btn-user btn-block">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  computed: mapState(["servers"]),
  data() {
    return {
      newCustomerServer: {},
      customerServers:[],
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      customerServerFormTitle: "Add Server",
      disc_text:true,
    };
  },
  created() {
    this.getCustomerServers();
  },
  methods: {
    
    getCustomerServers: function () {
      var customerId = this.$route.params.id;
      var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };

      // Get all Servers of this Customer
        axios.get(this.env_api_url + '/customers/'+customerId, {headers})
        .then((response) => {
          console.log("All Server of this customer --> ",response);
          this.customerServers = response.data.customer_servers;
          
        }).catch((error) => {
            console.warn('Your Error is :', error);
        })
      // Get all Servers of this Customer
    },

    formSubmit() {
      var data={
        'id':this.newCustomerServer.id,
        'ip_address':this.newCustomerServer.ip_address,
        'domain_name':this.newCustomerServer.domain_name,
        'product_name':this.newCustomerServer.product_name,
        'repo':this.newCustomerServer.repo,
        'doc_root':this.newCustomerServer.doc_root,
        'db_name':this.newCustomerServer.db_name,
        'db_user':this.newCustomerServer.db_user,
        'db_password':this.newCustomerServer.db_password,
        'php_my_admin':this.newCustomerServer.php_my_admin,
        'notes':this.newCustomerServer.notes,
        'serverable_id':this.$route.params.id,
        'serverable_type':"App/Models/User",
        'server_id':this.newCustomerServer.server_id,
      }

      // Products store
        var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };
        axios.post(this.env_api_url + '/customer_servers', data,{headers})
        .then((response) => {
          console.log("Server store successfully!", response);
          this.$notify({
            type: "success",
            title: "Success",
            text: "Save Successfully!",
          });
          this.resetForm();
          this.getCustomerServers();
        });
      // Products store
    },
    editCustomerServer(customerServer) {
      this.newCustomerServer = customerServer;
      this.customerServerFormTitle = "Edit Server";
    },
    deleteCustomerServer(customerServer) {

      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"), };
      if (confirm('Do you want to delete ' + customerServer.domain_name + '?')) {
        axios.delete(this.env_api_url + "/customer_servers/" + customerServer.id, {
            headers: headers,
          })
        .then((response) => {
          this.$notify({
            type: "success",
            title: "Success",
            text: "Deleted Successfully!",
          });
          console.log("Delete customer basic data: ", response);
          this.getCustomerServers();
        })
        .catch((error) => {
          console.warn("Your Error is :", error);
        });
      }
    },
    resetForm() {
      this.customerServerFormTitle = "Add Server";
      this.newCustomerServer = {};
    },
    showPasword() {
      this.disc_text = !this.disc_text; //Disc text class will fail
    },

  },
};
</script>

<style scoped>
  .text_As_Pswrd {
    -webkit-text-security: disc;
  }
  .zoom {
    transition: transform .2s;
  }
  .zoom:hover {
    color:#dfc04e;
    transform: scale(1.5);
  }
</style>