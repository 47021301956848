<template>
  <page-header :title="'New Customer'" />
  <form @submit="formSubmit" class="user">
    <div class="form-group row">
      <div class="col-sm-6 mb-3 mb-sm-0">
        <input
          v-model="firstName"
          type="text"
          class="form-control form-control-user"
          :class="{ 'is-invalid': v$.firstName.$error }"
          id="exampleFirstName"
          placeholder="First Name"
        />
        <span class="text-danger" v-if="v$.firstName.$error">
          <span
            class="help-block"
            v-for="validationError in v$.firstName.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      </div>
      <div class="col-sm-6">
        <input
          v-model="lastName"
          type="text"
          class="form-control form-control-user"
          :class="{ 'is-invalid': v$.lastName.$error }"
          id="exampleLastName"
          placeholder="Last Name"
        />
        <span class="text-danger" v-if="v$.lastName.$error">
          <span
            class="help-block"
            v-for="validationError in v$.lastName.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      </div>
    </div>
    <div class="form-group">
      <input
        v-model="email"
        type="email"
        class="form-control form-control-user"
        :class="{ 'is-invalid': v$.email.$error }"
        id="exampleInputEmail"
        placeholder="Email Address"
      />
      <span class="info-block text-danger" v-if="v$.email.$error">
        <span
          class="help-block"
          v-for="validationError in v$.email.$errors"
          :key="validationError"
        >
          {{ validationError.$message }}
        </span>
      </span>
    </div>
    <div class="form-group row">
      <div class="col-sm-6 mb-3 mb-sm-0">
        <input
          v-model="password"
          type="password"
          class="form-control form-control-user"
          :class="{ 'is-invalid': v$.password.$error }"
          id="exampleInputPassword"
          placeholder="Password"
        />
        <span class="text-danger" v-if="v$.password.$error">
          <span
            class="help-block"
            v-for="validationError in v$.password.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      </div>
      <div class="col-sm-6">
        <input
          v-model="confirmPassword"
          type="password"
          class="form-control form-control-user"
          :class="{ 'is-invalid': v$.confirmPassword.$error }"
          id="exampleRepeatPassword"
          placeholder="Repeat Password"
        />
        <span class="text-danger" v-if="v$.confirmPassword.$error">
          <span
            class="help-block"
            v-for="validationError in v$.confirmPassword.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      </div>
      
    </div>
    <button class="btn btn-primary btn-user btn-block">Submit</button>
    <hr />
  </form>
</template>

<script>
import useValidate from "@vuelidate/core";
import {
  required,
  minLength,
  email,
  maxLength,
  sameAs,
} from "@vuelidate/validators";
import PageHeader from "../SharedComponents/PageHeader.vue";
import axios from "axios";
export default {
  components: { PageHeader },
  data() {
    return {
      v$: useValidate(),
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      confirmPassword: null,
      customers_url:process.env.VUE_APP_SERVICE_URL + '/customers',
    };
  },
  validations() {
    return {
      firstName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      lastName: { required, minLength: minLength(3), maxLength: maxLength(50) },
      email: {
        required,
        email,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(50),
      },
      confirmPassword: {
        required,
        minLength: minLength(6),
        sameAs: sameAs(this.password),
        maxLength: maxLength(50),
      },
    };
  },
  methods: {
    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors");
      } else {
        var data = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          password: this.password,
        };
        var headers = {
          Authorization:
            `Bearer ` + localStorage.getItem("rezo_customers_user"),
        };
        this.$notify({
          type: "success",
          title: "Success",
          text: "Saved Successfully!",
        });
        axios
          .post(this.customers_url, data, { headers })
          .then((response) => {
            if (response.status === 200) {
              console.log("Form submitted successfully");
              this.$router.push({ path: "/customers" });
            }
            console.log(response);
          });
      }
    },
  },
};
</script>