<template>
  <!-- Page Heading -->

  <page-header
    :title="'Customers'"
    :hasLink="true"
    :linkTo="'/customers/create'"
    :linkText="'New Customer'"
  />

  <!-- DataTales Example -->
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-primary">Records <small>({{totalRows}})</small></h6>
    </div>
    <div class="card-body">
      <form class="form-inline">
        <div class="form-group">
          <input
            v-model="filters.name"
            class="form-control"
            placeholder="Name"
          />
        </div>

        <div class="form-group ml-1">
          <input
            v-model="filters.email"
            class="form-control"
            placeholder="Email"
          />
        </div>


        <div class="form-group ml-1">
          <button type="button" class="btn btn-danger" @click="this.filters={}">Reset</button>
        </div>
      </form>
      <div class="table-responsive">
        <table
          class="table table-bordered"
          id="dataTable"
          width="100%"
          cellspacing="0"
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Products</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="customer in customers" :key="customer">
              <td>{{ customer.first_name }} {{ customer.last_name }}</td>
              <td>{{ customer.email }}</td>
              <td>
                <span v-if="customer.products">
                  <small  v-for="productObj in customer.products" :key="productObj" class="badge badge-primary mr-1">{{productObj.product?productObj.product.name:''}}</small>
                </span>
                <span v-if="(customer.products).length<1">
                  <small class="badge badge-danger">No Products yet!</small>
                </span>
                </td>
              <td>{{ customer.created_at_formated_date }}</td>
              <td>
                <router-link class="btn btn-circle btn-primary  btn-sm"
                  :to="{ name: 'EditCustomer', params: { id: customer.id } }"
                >
                  <i class="fas fa-edit"></i>
                </router-link>
                <button v-on:click="deleteCustomer(customer.id)" class="btn btn-circle btn-danger  btn-sm ml-1">
                  <i
                  class="fas fa-trash"
                  
                ></i>
                </button>
                
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr></tr>
          </tfoot>
        </table>
        <div>
          <button  class="btn btn-success ml-2" @click="getCustomers()" >Previous</button>
          <button  class="btn btn-success ml-2" @click="getCustomers()">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../SharedComponents/PageHeader.vue";
import axios from "axios";



export default {
  components: { PageHeader },
  data() {
    return {
      filters:{
        
        },
      customers: [],
      totalRows: 0,
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      
      customers_url:process.env.VUE_APP_SERVICE_URL + '/customers',
    };
  },
  watch: {
    filters: {
        deep: true,
        handler () {
          if (!this.awaitingSearch) {
            setTimeout(() => {
              this.getCustomers()
              this.awaitingSearch = false;
            }, 700); // 1 sec delay
          }
          this.awaitingSearch = true;
          
        }
      },
    },
  methods: {
    getCustomers: function () {
      this.filters.type = this.$route.query.type?this.$route.query.type:"";
    
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .get(this.customers_url, { 
          headers,
          params: this.filters,
        })
        .then((response) => {
          this.totalRows = response.data.data.length;
          this.customers = response.data.data;
          this.customers_url = response.data.next_page_url;
        });
    },
    deleteCustomer: function (customerId) {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      if (confirm("Do you really want to delete?")) {
        axios
          .delete(this.env_api_url + "/customers/" + customerId, {
            headers: headers,
          })
          .then((response) => {
            this.$notify({
              type: "success",
              title: "Success",
              text: "Deleted Successfully!",
            });
            this.getCustomers();
            console.log("Delete customer basic data: ", response);
          })
          .catch((error) => {
            console.warn("Your Error is :", error);
          });
      }
    },
  },
  created() {
    this.getCustomers();
  },
};
</script>
