<template>
  <!-- Page Heading -->

  <page-header
    :title="'Leads'"
    :hasLink="true"
    :linkTo="'/leads/create'"
    :linkText="'New Lead'"
  />
  
  <!-- DataTales Example -->
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-primary">Records <small>({{totalRows}})</small></h6>
    </div>
    <div class="card-body">
      <form class="form-inline">
        <div class="form-group">
          <input
            v-model="filters.name"
            class="form-control"
            placeholder="Name"
          />
        </div>

        <div class="form-group ml-1">
          <input
            v-model="filters.email"
            class="form-control"
            placeholder="Email"
          />
        </div>


        <div class="form-group ml-1">
          <button type="button" class="btn btn-danger" @click="this.filters={}">Reset</button>
        </div>
      </form>
      <div class="table-responsive">
        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0" >
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Address</th>
              <th>City</th>
              <th>State</th>
              <th>Postal Code</th>
              <th>Phone #</th>
              <th>Actions</th>
            </tr>
          </thead>
          
          <tbody>
            <tr v-for="lead in leads" :key="lead">
              <td>{{lead.company_name}}</td>
              <td>{{lead.address}}</td>
              <td>{{lead.city}}</td>
              <td>{{lead.state}}</td>
              <td>{{lead.postal_code}}</td>
              <td>{{lead.phone_number}}</td>
              <td>
                <router-link :to="{name:'EditLead', params: { id:lead.id}}" >
                  <i class="fas zoom fa-edit"></i>
                </router-link >

                <i v-on:click="deleteLead(lead.id)" style="color:red;" class="fas ml-1 zoom fa-trash"></i>
              
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../SharedComponents/PageHeader.vue";
import axios from "axios";
export default {
  components: { PageHeader },
  data() {
    return {
      filters:{
        
      },
      leads: [],
      totalRows: 0,
      env_api_url: process.env.VUE_APP_SERVICE_URL
    };
  },
  watch: {
      filters: {
        deep: true,
        handler () {
          if (!this.awaitingSearch) {
            setTimeout(() => {
              this.getLeads()
              this.awaitingSearch = false;
            }, 700); // 1 sec delay
          }
          this.awaitingSearch = true;
        }
      },
    },
  methods: {
    getLeads: function ()
    {  
      this.filters.type = this.$route.query.type?this.$route.query.type:"";
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      
      axios
        .get(this.env_api_url + "/leads", { headers })
        .then((response) => {
          console.log("LEADS ",response);
          this.leads = response.data;
          this.totalRows = response.data.length;
        })
        .catch((error) => {
          console.warn("Your Error is :", error);
        });
    },

    deleteLead: function (leadId) {
       
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      if (confirm("Do you really want to delete?")) {
        axios
        .delete(this.env_api_url + "/leads/" + leadId, {
          headers: headers,
        })
        .then((response) => {
          this.getLeads();
          this.$notify({
            type: "success",
            title: "Success",
            text: "Deleted Successfully!",
          });
          console.log("Deleted Lead: ", response);
        })
        .catch((error) => {
          console.warn("Your Error is :", error);
        });
      }


    },
  },
  created() {
    this.getLeads();
  },
};
</script>
<style scoped>
  .zoom {
    transition: transform .2s;
  }
  .zoom:hover {
    transform: scale(1.5);
  }
</style>
