<template>
  <page-header :title="'New Admin'" />
  <form @submit="formSubmit" class="user">
      <!--((( Row Start )))-->
      <div class="form-group row">
        <div class="col-sm-6 mb-3 mb-sm-0">
          <input v-model="firstName" type="text" id="examplecompanyFirstName" placeholder="First Name" class="form-control" :class="{ 'is-invalid': v$.firstName.$error }" />
          <span class="text-danger" v-if="v$.firstName.$error">
            <span class="help-block" v-for="validationError in v$.firstName.$errors" :key="validationError">
              {{ validationError.$message }}
            </span>
          </span>
        </div>

        <div class="col-sm-6 mb-3 mb-sm-0">
          <input v-model="lastName" type="text" id="examplecompanyLastName" placeholder="Last Name" class="form-control" :class="{ 'is-invalid': v$.lastName.$error }" />
          <span class="text-danger" v-if="v$.lastName.$error">
            <span class="help-block" v-for="validationError in v$.lastName.$errors" :key="validationError">
              {{ validationError.$message }}
            </span>
          </span>
        </div>
      </div>
      
      <div class="form-group row">
        <div class="col-sm-4">
          <input v-model="address" type="text" id="exampleaddress" placeholder="Address" class="form-control" :class="{ 'is-invalid': v$.address.$error }" />
          <span class="text-danger" v-if="v$.address.$error">
            <span class="help-block" v-for="validationError in v$.address.$errors" :key="validationError">
              {{ validationError.$message }}
            </span>
          </span>
        </div>
      
        <div class="col-sm-4 mb-3 mb-sm-0">
          <input v-model="phoneNumber" type="number" placeholder="Phone Number" class="form-control" :class="{ 'is-invalid': v$.phoneNumber.$error }" />
          <span class="text-danger" v-if="v$.phoneNumber.$error">
            <span class="help-block" v-for="validationError in v$.phoneNumber.$errors" :key="validationError">
              {{ validationError.$message }}
            </span>
          </span>
        </div>

        <div class="col-sm-4 mb-3 mb-sm-0">
          <input v-model="email" type="text" id="examplecompanyEmail" placeholder="Email" class="form-control" :class="{ 'is-invalid': v$.email.$error }" />
          <span class="text-danger" v-if="v$.email.$error">
            <span class="help-block" v-for="validationError in v$.email.$errors" :key="validationError">
              {{ validationError.$message }}
            </span>
          </span>
        </div>
      </div>
      
      <div class="form-group row">
        <div class="col-sm-6">
          <input v-model="password" type="password" id="examplePassword" placeholder="Password" class="form-control" :class="{ 'is-invalid': v$.password.$error }" />
          <span class="text-danger" v-if="v$.password.$error">
            <span class="help-block" v-for="validationError in v$.password.$errors" :key="validationError">
              {{ validationError.$message }}
            </span>
          </span>
        </div>

        <div class="col-sm-6 mb-3 mb-sm-0">
          <input type="password" v-model="comfirmPassword" id="exampleComfirmPassword" placeholder="Comfirm password" class="form-control" :class="{ 'is-invalid': v$.comfirmPassword.$error }" />
          <span class="text-danger" v-if="v$.comfirmPassword.$error">
            <span class="help-block" v-for="validationError in v$.comfirmPassword.$errors" :key="validationError">
              {{ validationError.$message }}
            </span>
          </span>
        </div>
      </div>
      
      <div class="form-group row">
        <div class="col-sm-3 mb-3 mb-sm-0">
          <input v-model="city" type="text" placeholder="City" class="form-control" :class="{ 'is-invalid': v$.city.$error }" />
          <span class="text-danger" v-if="v$.city.$error">
            <span class="help-block" v-for="validationError in v$.city.$errors" :key="validationError">
              {{ validationError.$message }}
            </span>
          </span>
        </div>

        <div class="col-sm-3 mb-3 mb-sm-0">
          <select v-model="country"  id="exampleFormcountry" class="form-control" :class="{'is-invalid':v$.country.$error}" >
            <option selected :value="undefined">--Country--</option>
            <option>A</option>
            <option>B</option>
            <option>C</option>
          </select>
          <span class="text-danger" v-if="v$.country.$error">
            <span class="help-block" v-for="validationError in v$.country.$errors" :key="validationError">
              {{ validationError.$message }}
            </span>
          </span>
        </div>

        <div class="col-sm-3 mb-3 mb-sm-0">
          <select v-model="state" id="exampleFormstate" class="form-control" :class="{'is-invalid':v$.state.$error}" >
            <option selected :value="undefined">--State--</option>
            <option>A</option>
            <option>B</option>
            <option>C</option>
          </select>
          <span class="text-danger" v-if="v$.state.$error">
            <span class="help-block" v-for="validationError in v$.state.$errors" :key="validationError">
              {{ validationError.$message }}
            </span>
          </span>
        </div>

        <div class="col-sm-3 mb-3 mb-sm-0">
          <input v-model="zipCode" type="number" id="examplezipCode" placeholder="Zip Code" class="form-control" :class="{ 'is-invalid': v$.zipCode.$error }" />
          <span class="text-danger" v-if="v$.zipCode.$error">
            <span class="help-block" v-for="validationError in v$.zipCode.$errors" :key="validationError">
              {{ validationError.$message }}
            </span>
          </span>
        </div>
      </div>

      <div class="col-sm-6 form-inline mt-2 mb-2">
        <div class="form-group mr-2">
          <label class="mr-1">Active</label>
          <label class="switch">
            <input type="checkbox" v-model="active" />
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <button class="btn btn-primary btn-user btn-block">Submit</button>
      <hr />
  </form>
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapState } from "vuex";
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
} from "@vuelidate/validators";
import PageHeader from "../SharedComponents/PageHeader.vue";
import axios from "axios";

export default {
  components: { PageHeader },
  computed: mapState(["products"]),
  data() {

      return {
      v$: useValidate(),
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      firstName: null,
      lastName: null,
      address: null,
      phoneNumber: null,
      email: null,
      password: null,
      comfirmPassword: null,
      city: null,
      country: null,
      state: null,
      zipCode: null,
      active: false,
    };
  },
  validations() {
    return {
      firstName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      lastName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      address: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      phoneNumber: {
        required,
        number: true,
      },
      email: {
        required,
        email,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(50),
      },
      comfirmPassword: {
        required,
        minLength: minLength(6),
        sameAs: sameAs(this.password),
        maxLength: maxLength(50),
      },
      city: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      state: {
        required,
      },
      country: {
        required,
      },
      zipCode: {
        required,
        number: true,
      },
      
    };
  },
  methods: {
    formSubmit() {

      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors",this.v$.$error);
      } else {

        var data = {
          first_name: this.firstName,
          last_name: this.lastName,
          address : this.address,
          phone: this.phoneNumber,
          email: this.email,
          password: this.password,
          city: this.city,
          country: this.country,
          state: this.state,
          zip: this.zipCode,
          active: this.active,
        };

        var headers = {
          Authorization:
            `Bearer ` + localStorage.getItem("rezo_customers_user"),
        };
        axios
        .post(this.env_api_url + "/admins", data, { headers })
        .then((response) => {
          if (response.status === 200) {
            this.$notify({
              type: "success",
              title: "Success",
              text: "Save Successfully!",
            });
            console.log("Form submitted successfully");
            this.$router.push({ path: "/admins" });
          }
          console.log(response);
        });
      }
    },
  },
};
</script>
