<template>
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <form @submit="formSubmit" class="user">
          <div class="card-body">
            <div class="row">
              <div class="col-md-10">
                <h4 class="card-title">{{ salesInfoFormTitle }}</h4>
              </div>
              <div class="col-md-2">
                <button
                  v-show="newSalesInfo.follow_up_date"
                  type="button"
                  @click="resetForm()"
                  class="btn float-right btn-warning btn-circle btn-sm"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-12">
                <label for="">Follow Up Date</label>
                <input type="date" v-model="newSalesInfo.follow_up_date" placeholder="Domain name" class="form-control form-control-sm">
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-12">
                <label for="">Follow Up action</label>
                <select
                    v-model="newSalesInfo.follow_up_action"
                    class="form-control"
                    name=""
                    id=""
                  >
                    <option selected :value="undefined">--Select--</option>
                    <option value="1">Call</option>
                    <option value="2">Email</option>
                  </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-12">
                <label for="">Status</label>
                  <!-- <select
                    v-model="newSalesInfo.sales_info_status_id"
                    class="form-control"
                    name=""
                    id=""
                  >
                    <option selected :value="undefined">--Select--</option>
                    <option value="1">A</option>
                    <option value="2">B</option>
                  </select> -->
                  
                  <select v-model="newSalesInfo.sales_info_status_id" class="form-control form-control-sm">
                    <option selected :value="undefined">--Select--</option>
                    <option :value="saleStatus.id" v-for="saleStatus in salesStatuses" :key="saleStatus">
                      {{ saleStatus.status }}
                    </option>
                  </select>

              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <button :disabled="!newSalesInfo.follow_up_date" class="btn btn-primary btn-user btn-block" > Submit </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  computed: mapState(["salesStatuses"]),
  data() {
    return {
      
      newSalesInfo: {},
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      salesInfoFormTitle: "Add Sales information",
      leadSalesInfo: [],
      sales_info_url:process.env.VUE_APP_SERVICE_URL + '/sales_information',
    };
  },
  created() {
    this.getSalesInfo();
  },
  methods: {
    getSalesInfo: function () {
      var lead_id = this.$route.params.id;
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      // Get all sales information
      axios
        .get(this.sales_info_url + "/" + lead_id, { headers })
        .then((response) => {
          console.log("All sales information of lead --> ", response.data.sales_information);
          
          if (response.data.sales_information == null)
          {
            this.resetForm();  
          }
          else { 
            this.salesInfoFormTitle = "Edit Sales information";
            this.editSaleInfo(response.data.sales_information);
          }

        })
        .catch((error) => {
          console.warn("Your Error is :", error);
        });
    },

    formSubmit() {

      var data = {
        id: this.newSalesInfo.id,
        lead_id: this.$route.params.id,
        follow_up_date: this.newSalesInfo.follow_up_date,
        follow_up_action: this.newSalesInfo.follow_up_action,
        sales_info_status_id: this.newSalesInfo.sales_info_status_id,
      };
      
      // Sales info store
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .post(this.sales_info_url + "/", data, { headers })
        .then((response) => {
          console.log("sales information store successfully!", response);
          this.$notify({
            type: "success",
            title: "Success",
            text: "Saved Successfully!",
          });
          this.getSalesInfo();
        });
    },

    editSaleInfo(sales_information) {
      this.newSalesInfo = sales_information;
      this.customerProductFormTitle = "Edit Product";
    },
    
    resetForm() {
      this.salesInfoFormTitle = "Add Sales information";
      this.newSalesInfo = {};
    }
  },
};
</script>

<style>
</style>