<template>
  <!-- Page Heading -->

  <page-header
    :title="'Rezo Modules | Demo'"
    :hasLink="false"
    :linkTo="'/servers/create'"
    :linkText="'Add New Server'"
  />

  <!-- DataTales Example -->
  <div class="row">
    <div class="col-md-8">
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">List</h6>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table
              class="table table-bordered"
              id="dataTable"
              width="100%"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>IP</th>
                  
                  <th>Created At</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="rezo_module in rezo_modules" :key="rezo_module">
                  <td>{{ rezo_module.title }}</td>
                  <td>{{ rezo_module.server_ip }}</td>
                  
                  <td>{{ rezo_module.created_at }}</td>
                  <td>
                    
                    <button
                      v-on:click="editServer(rezo_module)"
                      class="btn btn-circle btn-primary btn-sm ml-1"
                    >
                      <i class="fas fa-edit"></i>
                    </button>

                    <button
                      v-on:click="deleteServer(rezo_module.id)"
                      class="btn btn-circle btn-danger btn-sm ml-1"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr></tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">{{rezo_module.id?"Edit":"Add"}} Rezo Module</h6>
        </div>
        <div class="card-body">
          
          <rezo-modules-edit :editServer="rezo_module" @eventGetRezoModuleMethod="getRezoModule" />
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import PageHeader from "../SharedComponents/PageHeader.vue";
import axios from "axios";

import RezoModulesEdit from './RezoModulesEdit.vue';

export default {
  components: { PageHeader, RezoModulesEdit },
  data() {
    return {
      rezo_module:{
        
      },
      servers: [],
      rezo_modules: [],
    };
  },

  methods: {
    getServers: function () {
      
      var type = this.$route.query.type;
      console.log("type", type);
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .get("http://127.0.0.1:8000/api/rezo_modules?type=" + type, { headers })
        .then((response) => {
          this.rezo_modules = response.data.data;
        });
    },
    editServer: function(server){
      this.server = server;
    },
    deleteServer: function (serverId) {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      if (confirm("Do you really want to delete?")) {
        axios
          .delete("http://127.0.0.1:8000/api/servers/" + serverId, {
            headers: headers,
          })
          .then((response) => {
            this.getServers();
            this.$notify({
              type: "success",
              title: "Success",
              text: "Deleted Successfully!",
            });
            console.log("Delete getServers basic data: ", response);
          })
          .catch((error) => {
            console.warn("Your Error is :", error);
          });
      }
    },
  },
  created() {
    this.getServers();
  },
};
</script>
