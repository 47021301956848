<template>
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Customer Products</h4>
          <p class="card-text"></p>

          <div class="row">
            <div class="col-sm-12">
              <div
                class="card mt-2 mt-4"
                v-for="customerProduct in customerProducts"
                :key="customerProduct"
              >
                <div class="card-body">
                  <p class="card-title">
                    Product: {{ customerProduct.product.name }}
                    <span class="float-right"
                      ><button
                        @click="editCustomerProduct(customerProduct)"
                        class="btn btn-circle btn-primary btn-sm mr-1"
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                      <button
                        class="btn btn-circle btn-danger btn-sm mr-1"
                        @click="deleteCustomerProduct(customerProduct)"
                      >
                        <i class="fas fa-trash"></i></button
                    ></span>
                  </p>
                  <p class="card-text" v-if="customerProduct.notes">
                    Notes: <small> {{ customerProduct.notes }}</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card">
        <form @submit="formSubmit" class="user">
          <div class="card-body">
            <div class="row">
              <div class="col-md-10">
                <h4 class="card-title">{{ customerProductFormTitle }}</h4>
              </div>
              <div class="col-md-2">
                <button
                  v-show="newCustomerProduct.product_id"
                  type="button"
                  @click="resetForm()"
                  class="btn float-right btn-warning btn-circle btn-sm"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <p class="card-text"></p>

            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label for="">Product Name</label>
                  <select
                    v-model="newCustomerProduct.product_id"
                    class="form-control"
                    name=""
                    id=""
                  >
                    <option selected :value="undefined">--Select--</option>
                    <option
                      :value="product.id"
                      v-for="product in products"
                      :key="product"
                    >
                      {{ product.name }}
                    </option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="">Notes</label>
                  <textarea
                    v-model="newCustomerProduct.notes"
                    class="form-control"
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>
            <button
              :disabled="!newCustomerProduct.product_id"
              class="btn btn-primary btn-user btn-block"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  computed: mapState(["products"]),
  data() {
    return {
      newCustomerProduct: {},
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      customerProductFormTitle: "Add Product",
      customerProducts: [],
    };
  },
  created() {
    this.getCustomerProducts();
  },
  methods: {
    getCustomerProducts: function () {
      var customerId = this.$route.params.id;
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      // Get all Products
      ///For Customer products called Eloquent from Customer Controller
      axios
        .get(this.env_api_url + "/customers/" + customerId, { headers })
        .then((response) => {
          console.log(
            "All Products of this customer --> ",
            response.data.customer_products
          );
          this.customerProducts = response.data.customer_products;
        })
        .catch((error) => {
          console.warn("Your Error is :", error);
        });
      // Get all Products
    },

    formSubmit() {
      var data = {
        id: this.newCustomerProduct.id,
        product_id: this.newCustomerProduct.product_id,
        user_id: this.$route.params.id,
        notes: this.newCustomerProduct.notes,
      };
      // Products store
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .post(this.env_api_url + "/customer_products", data, { headers })
        .then((response) => {
          console.log("Product store successfully!", response);
          this.$notify({
            type: "success",
            title: "Success",
            text: "Saved Successfully!",
          });
          this.resetForm();
          this.getCustomerProducts();
        });
      // Products store
    },

    editCustomerProduct(customerProduct) {
      this.newCustomerProduct = customerProduct;
      this.customerProductFormTitle = "Edit Product";
    },
    deleteCustomerProduct(customerProduct) {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      if (
        confirm("Do you want to delete " + customerProduct.product.name + "?")
      ) {
        axios
          .delete(
            this.env_api_url + "/customer_products/" + customerProduct.id,
            {
              headers: headers,
            }
          )
          .then((response) => {
            this.$notify({
              type: "success",
              title: "Success",
              text: "Deleted Successfully!",
            });
            console.log("Delete customer basic data: ", response);
            this.getCustomerProducts();
          })
          .catch((error) => {
            console.warn("Your Error is :", error);
          });
      }
    },
    resetForm() {
      this.customerProductFormTitle = "Add Product";
      this.newCustomerProduct = {};
    },
  },
};
</script>

<style>
</style>