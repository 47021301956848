<template>
  <!-- Page Heading -->
    <!-- Page Heading -->
                    <div class="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
                        <!-- <a href="#" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                                class="fas fa-download fa-sm text-white-50"></i> Generate Report</a> -->
                    </div>

                    <!-- Content Row -->
                    <div class="row">

                        <!-- Earnings (Monthly) Card Example -->
                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card border-left-primary shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                Bike Rental Clients</div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">44</div>
                                        </div>
                                        <div class="col-auto">
                                            
                                            <i class="fas fa-bicycle fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Earnings (Monthly) Card Example -->
                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card border-left-success shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                                GMS Clients</div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">25</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-mountain fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Earnings (Monthly) Card Example -->
                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card border-left-info shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Ski Rental Clients
                                            </div>
                                            <div class="row no-gutters align-items-center">
                                                <div class="col-auto">
                                                    <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">87</div>
                                                </div>
                                                <div class="col">
                                                    <div class="progress progress-sm mr-2">
                                                        <div class="progress-bar bg-info" role="progressbar"
                                                            style="width: 50%" aria-valuenow="50" aria-valuemin="0"
                                                            aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-skiing-nordic fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Pending Requests Card Example -->
                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card border-left-warning shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                                Pending Requests</div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">18</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-comments fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- TODO LISTS SECTION-->

                    <div class="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 class="h3 mb-0 text-gray-800">Todo List</h1>
                    </div>

                     <div class="row">
                       <!-- Todo (Today) Card Example -->
                        <div class="col-xl-4 col-md-12 mb-4">
                            <div class="card border-left-danger shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="h5 font-weight-bold text-center text-primary text-uppercase mb-1">
                                                Today
                                            </div>
                                            <hr>
                                           <form @submit="formSubmit" class="user">
                                               <div class="form-group row">
                                                   <div class="col-md-12 mb-3">
                                                      <input
                                                      v-model="title"
                                                      type="text"
                                                      class="form-control form-control-sm"
                                                      :class="{ 'is-invalid': v$.title.$error }"
                                                      id="exampleTitle"
                                                      placeholder="Title"
                                                      />
                                                      <span class="text-danger" v-if="v$.title.$error">
                                                         <span
                                                               class="help-block"
                                                               v-for="validationError in v$.title.$errors"
                                                               :key="validationError"
                                                               >
                                                             {{ validationError.$message }}
                                                         </span>
                                                        </span>
                                                    </div>
                                                    <div class="col-md-8 mb-3">
                                                      <input
                                                      v-model="display_order"
                                                      type="text"
                                                      class="form-control form-control-sm"
                                                      :class="{ 'is-invalid': v$.display_order.$error }"
                                                      id="exampleDisplayOrder"
                                                      placeholder="Display Order"
                                                      />
                                                      <span class="text-danger" v-if="v$.display_order.$error">
                                                         <span
                                                               class="help-block"
                                                               v-for="validationError in v$.display_order.$errors"
                                                               :key="validationError"
                                                               >
                                                             {{ validationError.$message }}
                                                         </span>
                                                        </span>
                                                    </div>
                                                    <div class="col-md-4 md-form form-sm">
                                                      <button class="btn btn-primary btn-sm btn-block"><i class="fas fa-plus-circle fa-sm m-1"></i>Add</button>
                                                    </div>
                                               </div>
                                           </form>
                                            <hr>
                                        </div>
                                        <div class="col-md-12">
                                            <i class="fas fa-check" style="color:#2e59d9;"></i><span style="margin-left:10px;">Meeting with John at 9pm</span>
                                                <button class="btn btn-circle btn-primary btn-sm ml-3"><i class="fas fa-edit"></i></button>
                                                <button class="btn btn-circle btn-danger btn-sm ml-1"><i class="fas fa-trash"></i></button><hr>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Todo (Weekly) Card Example -->
                       <div class="col-xl-4 col-md-12 mb-4">
                            <div class="card border-left-warning shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="h5 font-weight-bold text-center text-primary text-uppercase mb-1">
                                                This Week
                                            </div>
                                            <hr>
                                            <form class="user">
                                               <div class="form-group row">
                                                   <div class="col-md-12 mb-3">
                                                     <input
                                                      v-model="type2"
                                                      type="hidden"
                                                      />
                                                      <input
                                                      v-model="title2"
                                                      type="text"
                                                      class="form-control form-control-sm"
                                                      id="exampleTitle"
                                                      placeholder="Title"
                                                      />
                                                    </div>
                                                    <div class="col-md-8 mb-3">
                                                      <input
                                                      v-model="display_order2"
                                                      type="text"
                                                      class="form-control form-control-sm"
                                                      id="exampleDisplayOrder"
                                                      placeholder="Display Order"
                                                      />
                                                    </div>
                                                    <div class="col-md-4 md-form form-sm">
                                                      <button class="btn btn-primary btn-sm btn-block"><i class="fas fa-plus-circle fa-sm m-1"></i>Add</button>
                                                    </div>
                                               </div>
                                           </form>
                                            <hr>
                                        </div>
                                        <div class="col-md-12">
                                            <i class="fas fa-check" style="color:#2e59d9;"></i><span style="margin-left:10px;">Meeting with John at 9pm</span>
                                                <button class="btn btn-circle btn-primary btn-sm ml-3"><i class="fas fa-edit"></i></button>
                                                <button class="btn btn-circle btn-danger btn-sm ml-1"><i class="fas fa-trash"></i></button><hr>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Todo (Monthly) Card Example -->
                        <div class="col-xl-4 col-md-12 mb-4">
                            <div class="card border-left-info shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="h5 font-weight-bold text-center text-primary text-uppercase mb-1">
                                                Next 30 Days
                                            </div>
                                            <hr>
                                            <form class="user">
                                               <div class="form-group row">
                                                   <div class="col-md-12 mb-3">
                                                      <input
                                                      v-model="type3"
                                                      type="hidden"
                                                      />
                                                      <input
                                                      v-model="title3"
                                                      type="text"
                                                      class="form-control form-control-sm"
                                                      id="exampleTitle"
                                                      placeholder="Title"
                                                      />
                                                    </div>
                                                    <div class="col-md-8 mb-3">
                                                      <input
                                                      v-model="display_order3"
                                                      type="text"
                                                      class="form-control form-control-sm"
                                                      id="exampleDisplayOrder"
                                                      placeholder="Display Order"
                                                      />
                                                    </div>
                                                    <div class="col-md-4 md-form form-sm">
                                                      <button class="btn btn-primary btn-sm btn-block"><i class="fas fa-plus-circle fa-sm m-1"></i>Add</button>
                                                    </div>
                                               </div>
                                           </form>
                                            <hr>
                                        </div>
                                        <div class="col-md-12">
                                            <i class="fas fa-check" style="color:#2e59d9;"></i><span style="margin-left:10px;">Meeting with John at 9pm</span>
                                                <button class="btn btn-circle btn-primary btn-sm ml-3"><i class="fas fa-edit"></i></button>
                                                <button class="btn btn-circle btn-danger btn-sm ml-1"><i class="fas fa-trash"></i></button><hr>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                    
</template>

<script>

import useValidate from "@vuelidate/core";
import {
  required,
  minLength,
  maxLength,
} from "@vuelidate/validators";

import { mapState } from 'vuex'
import axios from "axios";
export default {
  name: 'Dashboard',
  data() {
    return {
      v$: useValidate(),
      title: null,
      display_order: null,
    };
  },

validations() {
    return {
    title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      display_order: { required, minLength: minLength(3), maxLength: maxLength(50) },
    };
},

  computed: mapState([
    'products', 
    'productsInBag' 
  ]),

  methods: {
    addToBag(product) {
      product.quantity = 1;
      this.$store.dispatch('addToBag', product);
    },
    isInBag(product) {
      return this.productsInBag.find(item => item.id == product.id)
    }
  },
  formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors");
      } else {
        var data = {
        task_title: this.title,
        display_order: this.display_order,
        };
        var headers = {
          Authorization:
            `Bearer ` + localStorage.getItem("rezo_customers_user"),
        };
        this.$notify({
          type: "success",
          title: "Success",
          text: "Saved Successfully!",
        });
        axios
          .post(process.env.VUE_APP_SERVICE_URL + "/todo", data, { headers })
          .then((response) => {
            if (response.status === 200) {
              console.log("Form submitted successfully");
              this.$router.push({ path: "/todo" });
            }
            console.log(response);
          });
      }
    },
}
</script>
<style>
#task_list {
list-style: none;
}
</style>