<template>
  <h1 class="h3 mb-2 text-gray-800">
    {{ title }}
    <router-link v-if="hasLink" class="btn btn-primary" :to="linkTo"
      ><i class="fa fa-plus"></i> {{linkText}}</router-link
    >
  </h1>
</template>

<script>
export default {
  props: ["title", "hasLink",'linkTo','linkText'],
};
</script>

<style>
</style>