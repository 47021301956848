<template>
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Customer Support Services</h4>
          <p class="card-text"></p>

          <div class="row">
            <div class="col-sm-12">
              <div class="card mt-2 mt-4" v-for="customerService in customerServices" :key="customerService" >
                <div class="card-body">
                  <p class="card-title">
                    Service: {{ customerService.support_service.title }}
                    <span class="float-right"
                      ><button
                        @click="editcustomerService(customerService)"
                        class="btn btn-circle btn-primary btn-sm mr-1"
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                      <button
                        class="btn btn-circle btn-danger btn-sm mr-1"
                        @click="deletecustomerService(customerService)"
                      >
                        <i class="fas fa-trash"></i></button
                    ></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card">
        <form @submit="formSubmit" class="user">
          <div class="card-body">
            <div class="row">
              <div class="col-md-10">
                <h4 class="card-title">{{ customerServiceFormTitle }}</h4>
              </div>
              <div class="col-md-2">
                <button
                  v-show="newCustomerService.service_id"
                  type="button"
                  @click="resetForm()"
                  class="btn float-right btn-warning btn-circle btn-sm"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <p class="card-text"></p>

            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label for="">Service Name</label>
                  <select
                    v-model="newCustomerService.service_id"
                    class="form-control"
                    name=""
                    id=""
                  >
                  <option selected :value="undefined">--Select--</option>
                    <option
                      :value="service.id"
                      v-for="service in supportServices"
                      :key="service"
                    >
                      {{ service.title }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <button
              :disabled="!newCustomerService.service_id"
              class="btn btn-primary btn-user btn-block"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
export default {
  
  data() {
    return {
      newCustomerService: {},
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      supportServices: {},
      customerServiceFormTitle: "Add Support Service",
      customerServices: [],
    };
  },
  created() {
    this.getSupportServices();
    this.getcustomerServices();
  },
  
  methods: {
    getSupportServices: function(){
      var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };
      axios.get(this.env_api_url + '/support_services', {headers})
      .then(response => {
        console.log("loadSupportServices from Database -->", response.data)
        this.supportServices = response.data;
        
      })
    },
    getcustomerServices: function () {
       var customerId = this.$route.params.id;
      var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };

      // Get all Servers of this Customer
        axios.get(this.env_api_url + '/customers/'+customerId, {headers})
        .then((response) => {
          console.log("All Support Services of this customer --> ",response);
          this.customerServices = response.data.customer_support_services;
          
        }).catch((error) => {
            console.warn('Your Error is :', error);
        })
      // Get all Servers of this Customer
    },

    formSubmit() {
      var data = {
        id: this.newCustomerService.id,
        service_id: this.newCustomerService.service_id,
        user_id: this.$route.params.id
      };

      // Support service store
        var headers = {
          Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
        };
        axios
          .post(this.env_api_url + "/customer_support_services", data, { headers })
          .then((response) => {
            this.$notify({
              type: "success",
              title: "Success",
              text: "Saved Successfully!",
            });
            console.log("Support Service store successfully!", response);
            this.resetForm();
            this.getcustomerServices();
          });
      // Support service store
    },

    editcustomerService(customerService) {
      this.newCustomerService = customerService;
      this.customerServiceFormTitle = "Edit Support Service";
    },
    deletecustomerService(customerService) {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      if (
        confirm("Do you want to delete " + customerService.support_service.title + "?")
      ) {
        axios
          .delete(
            this.env_api_url + "/customer_support_services/" + customerService.id,
            {
              headers: headers,
            }
          )
          .then((response) => {
            this.$notify({
              type: "success",
              title: "Success",
              text: "Deleted Successfully!",
            });
            console.log("Deleted Support Service: ", response);
            this.getcustomerServices();
          })
          .catch((error) => {
            console.warn("Your Error is :", error);
          });
      }
    },
    resetForm() {
      this.customerServiceFormTitle = "Add Support Service";
      this.newCustomerService = {};
    },
  },
};
</script>

<style>
</style>