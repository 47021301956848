<template>
  <page-header :title="'Edit Customer'" />
  <ul class="nav nav-pills nav-fill mt-5 mb-5">
    
    <li class="nav-item">
      <a
        class="nav-link "
        :class="{'active': activeTab == 'basic'}" 
        @click="goToTab('basic')"
        href="javascript:void(0)"
        ><i class="fa fa-user"></i> Basic</a
      >
    </li>

    <li class="nav-item">
      <a class="nav-link"
        :class="{'active':activeTab == 'products'}"
        href="javascript:void(0)"
        @click="goToTab('products')"
        ><i class="fa fa-project-diagram"></i> Products</a
      >
    </li>


    <li class="nav-item">
      <a class="nav-link" 
        :class="{'active':activeTab == 'servers'}"  
        href="javascript:void(0)"
        @click="goToTab('servers')"
        ><i class="fa fa-server"></i> Servers</a
      >
    </li>
    <li class="nav-item">
      <a class="nav-link" 
      :class="{'active': activeTab == 'Services'}" 
      href="javascript:void(0)"
      @click="goToTab('Services')"
        ><i class="fab fa-servicestack"></i> Support Services</a
      >
    </li>
    <!-- <li class="nav-item">
      <a class="nav-link" 
      :class="{'active': activeTab == 'actionItems'}" 
      href="javascript:void(0)"
       @click="goToTab('actionItems')"
        ><i class="fa fa-server"></i> Action Items</a
      >
    </li> -->
    <!-- <li class="nav-item">
      <a class="nav-link" 
      :class="{'active': activeTab == 'notes'}" 
      href="javascript:void(0)"
       @click="goToTab('notes')"
        ><i class="fa fa-copy"></i> Notess</a
      >
    </li> -->
  </ul>
  <section v-if="activeTab == 'basic'">
    <customer-form />
  </section>
  <section v-if="activeTab == 'servers'">
    <customer-servers />
  </section>
  <section v-if="activeTab == 'products'">
    <customer-products />
  </section>
  <section v-if="activeTab == 'notes'">
    <customer-notes />
  </section>
  <section v-if="activeTab == 'actionItems'">
    <customer-actionItems />
  </section>
  <section v-if="activeTab == 'Services'">
    <customer-Services />
  </section>
</template>

<script>
import PageHeader from "../SharedComponents/PageHeader.vue";
import CustomerForm from "./partials/CustomerForm.vue";
import CustomerProducts from './partials/CustomerProducts.vue';
import CustomerServers from "./partials/CustomerServers.vue";
import { useRoute } from 'vue-router'
import CustomerNotes from './partials/CustomerNotes.vue';
import CustomerActionItems from './partials/actionItems.vue'
import CustomerServices from './partials/CustomerServices.vue'
export default {
  setup(){

    
  },
  components: { PageHeader, CustomerForm, CustomerServers, CustomerProducts ,CustomerNotes,CustomerActionItems, CustomerServices },
  data() {
    const route = useRoute()
    console.log(route.query)
    return {
      activeTab: route.query.activeTab==undefined?'basic':route.query.activeTab,
    };
  },
  methods: {
    goToTab(param) {
      //router.push({ path: '', query: { plan: 'private' }})
      this.$router.replace({ query: { activeTab: param } });
      this.activeTab = param;
    },
  },
};
</script>
