<template>
  <page-header :title="'Edit Lead'" />

  <ul class="nav nav-pills nav-fill mt-5 mb-5">
    
    <li class="nav-item">
      <a
        class="nav-link "
        :class="{'active': activeTab == 'basic'}" 
        @click="goToTab('basic')"
        href="javascript:void(0)"
        ><i class="fa fa-user"></i> Basic</a
      >
    </li>

    <li class="nav-item">
      <a class="nav-link"
        :class="{'active':activeTab == 'salesInfo'}"
        href="javascript:void(0)"
        @click="goToTab('salesInfo')"
        ><i class="fa fa-info-circle"></i> Sales info</a
      >
    </li>

    <li class="nav-item">
      <a class="nav-link"
        :class="{'active':activeTab == 'contactDetails'}"
        href="javascript:void(0)"
        @click="goToTab('contactDetails')"
        ><i class="fa fa-book"></i> Contact Details</a
      >
    </li>

  </ul>

  <section v-if="activeTab == 'basic'">
    <lead-form />
  </section>

  <section v-if="activeTab == 'salesInfo'">
    <lead-salesInfo />
  </section>

  <section v-if="activeTab == 'contactDetails'">
    <lead-contactDetails />
  </section>

</template>

<script>
import PageHeader from "../SharedComponents/PageHeader.vue";
import LeadForm from "./partials/LeadForm.vue";
import LeadSalesInfo from './partials/LeadSalesInfo.vue';
import LeadContactDetails from './partials/LeadContactDetails.vue';
import { useRoute } from 'vue-router'

export default {
  setup(){

  },

  components: { 
    PageHeader, 
    LeadForm,
    LeadSalesInfo,
    LeadContactDetails
  },
  
  data() {
    const route = useRoute()
    console.log(route.query)
    return {
      activeTab: route.query.activeTab==undefined?'basic':route.query.activeTab,
    };
  },

  methods: {
    goToTab(param) {
      //router.push({ path: '', query: { plan: 'private' }})
      this.$router.replace({ query: { activeTab: param } });
      this.activeTab = param;
    },
  },

};
</script>
