<template>
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Contact Details</h4>
          <p class="card-text"></p>

          <div class="row">
            <div class="col-sm-12">
              <div class="card mt-2 mt-4" v-for="ContactDetail in leadContactDetails" :key="ContactDetail" >
                
                <div class="card-body">
                  <p class="card-title">
                    Product: {{ ContactDetail.lead_products.name }}
                    <span class="float-right"
                      ><button
                        @click="editContactDetail(ContactDetail)"
                        class="btn btn-circle btn-primary btn-sm mr-1"
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                      <button
                        class="btn btn-circle btn-danger btn-sm mr-1"
                        @click="deleteContactDetail(ContactDetail)"
                      >
                        <i class="fas fa-trash"></i></button
                    ></span>
                  </p>
                  <p class="card-text" v-if="ContactDetail.contact_name">
                    Contact Name: <small> {{ ContactDetail.contact_name }}</small> <br>
                    Contact Title: <small> {{ ContactDetail.contact_title }}</small> <br>
                    Contact Phone #: <small> {{ ContactDetail.contact_phone_number }}</small> <br>
                    Contact Email: <small> {{ ContactDetail.contact_email }}</small> <br>
                    Website URL: <small> {{ ContactDetail.website_url }}</small> <br>
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card">
        <form @submit="formSubmit" class="user">
          <div class="card-body">
            <div class="row">
              <div class="col-md-10">
                <h4 class="card-title">{{ leadContactFormTitle }}</h4>
              </div>
              <div class="col-md-2">
                <button
                  v-show="newLeadContactDetail.product_id"
                  type="button"
                  @click="resetForm()"
                  class="btn float-right btn-warning btn-circle btn-sm"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <p class="card-text"></p>

            <div class="row">

              <div class="col-md-6">
                <div class="mb-3">
                  <label for="">Product Name</label>
                  <select v-model="newLeadContactDetail.product_id" class="form-control form-control-sm" name="" id="" >
                    <option selected :value="undefined">--Select--</option>
                    <option :value="product.id" v-for="product in products" :key="product" >
                      {{ product.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-6 mb-3">
                <label for="">Contact Name</label>
                <input v-model="newLeadContactDetail.contact_name" type="text" placeholder="Contact name" class="form-control form-control-sm">
              </div>

               <div class="col-md-6 mb-3">
                <label for="">Contact Title</label>
                <input v-model="newLeadContactDetail.contact_title"  type="text" placeholder="Contact title" class="form-control form-control-sm">
              </div>

               <div class="col-md-6 mb-3">
                <label for="">Contact Phone #</label>
                <input v-model="newLeadContactDetail.contact_phone_number" type="number" placeholder="Contact phone number" class="form-control form-control-sm">
              </div>
               <div class="col-md-6 mb-3">
                <label for="">Email Address</label>
                <input v-model="newLeadContactDetail.contact_email" type="text" placeholder="Contact Email address" class="form-control form-control-sm">
              </div>
               <div class="col-md-6 mb-3">
                <label for="">Website URL</label>
                <input v-model="newLeadContactDetail.website_url"  type="text" placeholder="Website URL" class="form-control form-control-sm">
              </div>

            </div>

            <button :disabled="!newLeadContactDetail.product_id" class="btn btn-primary btn-user btn-block" >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  computed: mapState(["products"]),
  data() {
    return {
      newLeadContactDetail: {},
      leadContactFormTitle: "Add Contact Details",
      leadContactDetails: [],
      env_api_url: process.env.VUE_APP_SERVICE_URL
    };
  },
  
  created() {
    this.getLeadContactDetails();
  },

  methods: {
    getLeadContactDetails: function () {
      var lead_id = this.$route.params.id;
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      // Get all Contact Details
      axios
      .get(this.env_api_url + "/leads/" + lead_id, { headers })
      .then((response) => {
        console.log(
          "All Contact Details of this lead --> ",response.data.contactDetails
        );
        this.leadContactDetails = response.data.contactDetails;
      })
      .catch((error) => {
        console.warn("Your Error is :", error);
      });
    },

    formSubmit() {

      var data = {

        id: this.newLeadContactDetail.id,
        product_id: this.newLeadContactDetail.product_id,
        lead_id: this.$route.params.id,
        contact_name: this.newLeadContactDetail.contact_name,
        contact_title: this.newLeadContactDetail.contact_title,
        contact_phone_number: this.newLeadContactDetail.contact_phone_number,
        contact_email: this.newLeadContactDetail.contact_email,
        website_url: this.newLeadContactDetail.website_url,
      };

      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .post(this.env_api_url + "/contact_details", data, { headers })
        .then((response) => {
          this.$notify({
            type: "success",
            title: "Success",
            text: "Saved Successfully!",
          });
          console.log("Contact Details store successfully!", response);
          this.resetForm();
          this.getLeadContactDetails();
        });
    },

    editContactDetail(ContactDetail) {
      this.newLeadContactDetail = ContactDetail;
      this.customerProductFormTitle = "Edit Contact Detail";
    },

    deleteContactDetail(ContactDetail) {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      if (
        confirm("Do you want to delete " + ContactDetail.contact_name + "?")
      ) {
        axios
        .delete(
          this.env_api_url + "/contact_details/" + ContactDetail.id,
          {
            headers: headers,
          }
        )
        .then((response) => {
          this.$notify({
            type: "success",
            title: "Success",
            text: "Deleted Successfully!",
          });
          console.log("Delete Contact Detail: ", response);
          this.getLeadContactDetails();
        })
        .catch((error) => {
          console.warn("Your Error is :", error);
        });
      }
    },

    resetForm() {
      this.leadContactFormTitle = "Add Contact Details";
      this.newLeadContactDetail = {};
    },

  },
};
</script>