<template>
  <component :is="layout"> </component>
  <notifications />
</template>

<script>
import { useRoute } from "vue-router";
import { computed } from "vue";

import Login from "./views/Auth/Login.vue";
import AdminLayout from "./views/layouts/admin/AdminLayout.vue";

export default {
  
  components: { Login, AdminLayout },
  data() {
    return {
      path: null,
    };
  },
  created() {

    console.log("VUE_APP_TITLE",process.env.VUE_APP_TITLE)

    this.$store.dispatch("authUser");
    this.$store.dispatch("loadProducts");
    this.$store.dispatch("loadServers");
    this.$store.dispatch("loadSalesStatuses");
    
    const route = useRoute();
    this.path = computed(() => route.path);
  },
  computed: {
    layout() {
      console.log(this.$route.meta.layout);
      return this.$route.meta.layout ? Login : AdminLayout;
    },
  },
};
</script>



