<template>
  <page-header :title="'New Lead'" />
  <form @submit="formSubmit" class="user">
      <!--((( Row Start )))-->
      <div class="form-group row">
        <div class="col-sm-4 mb-3 mb-sm-0">
          <input
            v-model="companyName"
            type="text"
            class="form-control " :class="{'is-invalid':v$.companyName.$error}"
            id="examplecompanyName"
            placeholder="Company Name"
          />
          <span class="text-danger" v-if="v$.companyName.$error">
            <span class="help-block" v-for="validationError in v$.companyName.$errors" :key="validationError">
              {{ validationError.$message }}
            </span>
          </span>
        </div>

        <!-- <div class="col-sm-4 mb-3 mb-sm-0">
          <select
            class="form-control" name="" id="" >
            <option selected :value="undefined">--Select--</option>
            <option :value="product.id" v-for="product in products" :key="product" >
              {{ product.name }}
            </option>
          </select>
        </div> -->
      </div>
      
      <div class="form-group row">
        <div class="col-sm-10">
          <input
            v-model="address"
            type="text"
            class="form-control " :class="{'is-invalid':v$.address.$error}"
            id="exampleaddress"
            placeholder="Address"
          />
          <span class="text-danger" v-if="v$.address.$error">
            <span class="help-block" v-for="validationError in v$.address.$errors" :key="validationError">
              {{ validationError.$message }}
            </span>
          </span>
        </div>
      </div>
      
      <div class="form-group row">
        <div class="col-sm-4 mb-3 mb-sm-0">
          <input
            v-model="city"
            type="text"
            class="form-control " :class="{'is-invalid':v$.address.$error}"
            placeholder="City"
          />
          <span class="text-danger" v-if="v$.city.$error">
            <span class="help-block" v-for="validationError in v$.city.$errors" :key="validationError">
              {{ validationError.$message }}
            </span>
          </span>
        </div>

        <div class="col-sm-4 mb-3 mb-sm-0">
          <select v-model="state" class="form-control " :class="{'is-invalid':v$.state.$error}" style="" id="exampleFormstate" >
            <option selected :value="undefined">--State--</option>
            <option>A</option>
            <option>B</option>
            <option>C</option>
          </select>
          <span class="text-danger" v-if="v$.state.$error">
            <span class="help-block" v-for="validationError in v$.state.$errors" :key="validationError">
              {{ validationError.$message }}
            </span>
          </span>
        </div>

        <div class="col-sm-2 mb-3 mb-sm-0">
          <input
            v-model="postalCode"
            type="number"
            class="form-control " :class="{'is-invalid':v$.postalCode.$error}"
            id="examplepostalCode"
            placeholder="Postal Code"
          />
          <span class="text-danger" v-if="v$.postalCode.$error">
            <span class="help-block" v-for="validationError in v$.postalCode.$errors" :key="validationError">
              {{ validationError.$message }}
            </span>
          </span>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-4 mb-3 mb-sm-0">
          <input
            v-model="phoneNumber"
            type="number"
            class="form-control " :class="{'is-invalid':v$.phoneNumber.$error}"
            placeholder="Phone Number"
          />
          <span class="text-danger" v-if="v$.phoneNumber.$error">
            <span class="help-block" v-for="validationError in v$.phoneNumber.$errors" :key="validationError" >
              {{ validationError.$message }}
            </span>
          </span>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-6 form-inline mt-2 mb-2">
          <div class="col-sm-6 form-inline mt-3">
            <div class="form-group mr-2">
              <label class="mr-1">Active</label>
              <label class="switch">
                <input type="checkbox" value="" checked v-model="is_active" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <button class="btn btn-primary btn-user btn-block">Submit</button>
      <hr />
  </form>
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapState } from "vuex";
import {
  required,
  minLength,
  maxLength,
} from "@vuelidate/validators";
import PageHeader from "../SharedComponents/PageHeader.vue";
import axios from "axios";

export default {
  components: { PageHeader },
  computed: mapState(["products"]),
  data() {

      return {
      v$: useValidate(),
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      companyName: null,
      address: null,
      city: null,
      state: null,
      postalCode: null,
      phoneNumber: null,
      is_active: 0,
    };
  },
  validations() {
    return {
      companyName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      address: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      city: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      state: {
        required,
      },
      postalCode: {
        required,
        number: true,
      },
      phoneNumber: {
        required,
        number: true,
      },
    };
  },
  methods: {
    formSubmit() { 
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors",this.v$.$error);
      } else {

        var data = {
          company_name: this.companyName,
          address: this.address,
          city: this.city,
          state: this.state,
          postal_code: this.postalCode,
          phone_number: this.phoneNumber,
          is_active: this.is_active,
        };

        var headers = {
          Authorization:
            `Bearer ` + localStorage.getItem("rezo_customers_user"),
        };
        axios
        .post(this.env_api_url + "/leads", data, { headers })
        .then((response) => {
          if (response.status === 200) {
            this.$notify({
              type: "success",
              title: "Success",
              text: "Save Successfully!",
            });
            console.log("Form submitted successfully");
            this.$router.push({ path: "/leads" });
          }
          console.log(response);
        });
      }
    },
  },
};
</script>
