<template>
  <form @submit="formSubmit" class="user">
    <!--((( Row Start )))-->
    <div class="form-group row">
      <div class="col-sm-12">
        <input
          v-model="server.name"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': v$.server.name.$error }"
          id="examplecompanyName"
          placeholder="Server Name"
        />
        <span class="text-danger" v-if="v$.server.name.$error">
          <span
            class="help-block"
            v-for="validationError in v$.server.name.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12">
        <input
          v-model="server.server_ip"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': v$.server.server_ip.$error }"
          id="server_ip"
          placeholder="Server IP"
        />
        <span class="text-danger" v-if="v$.server.server_ip.$error">
          <span
            class="help-block"
            v-for="validationError in v$.server.server_ip.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12">
        <p><strong>SSH Details</strong></p>
      </div>
      <div class="col-sm-6">
        <input
          v-model="server.ssh_login"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': v$.server.ssh_login.$error }"
          id="ssh_login"
          placeholder="Login"
        />
        <span class="text-danger" v-if="v$.server.ssh_login.$error">
          <span
            class="help-block"
            v-for="validationError in v$.server.ssh_login.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      
      </div>
      <div class="col-sm-6">
        
        <input
          v-model="server.ssh_password"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': v$.server.ssh_password.$error }"
          id="server_ip"
          placeholder="Password"
        />
        <span class="text-danger" v-if="v$.server.ssh_password.$error">
          <span
            class="help-block"
            v-for="validationError in v$.server.ssh_password.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-6">
        <input
          v-model="server.display_order"
          type="number"
          min="0"
          class="form-control"
          :class="{ 'is-invalid': v$.server.display_order.$error }"
          id="display_order"
          placeholder="Display Order"
        />
        <span class="text-danger" v-if="v$.server.display_order.$error">
          <span
            class="help-block"
            v-for="validationError in v$.server.display_order.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      </div>
    </div>
    <button class="btn btn-primary btn-user btn-block">{{server.id?"Update":"Submit"}}</button>
    <hr />
  </form>
</template>

<script>
import useValidate from "@vuelidate/core";

import { required, minLength,integer, maxLength } from "@vuelidate/validators";

import axios from "axios";

export default {
  components: {},
  props: ["editServer"],
  data() {
    return {
      v$: useValidate(),
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      server: {},
    };
  },
  watch: {
    editServer: {
      immediate: true,
      handler (newVal, oldVal) {
        console.log(oldVal);
        this.server = newVal;
      }
    },
  },
  validations: {
    server: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      server_ip: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      ssh_login: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      ssh_password: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      display_order: {
        integer,
      },
    },
  },
  methods: {
    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors", this.v$.$error);
      } else {
        var data = this.server;
        console.log("data submitting", data);
        var headers = {
          Authorization:
            `Bearer ` + localStorage.getItem("rezo_customers_user"),
        };
        var message = "Saved Successfully!"
        var type = "success"
        if(data.id){
          message = "Updated Successfully!"
          type = "info"
        }
        this.$notify({
          type: type,
          title: "Success",
          text: message,
        });
        axios
          .post(this.env_api_url + "/servers", data, { headers })
          .then((response) => {
            if (response.status === 200) {
              this.server= {};
              this.v$.$reset();
              this.$emit("eventGetServersMethod")
            }
            
          });
      }
    },
  },
};
</script>
