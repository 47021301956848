<template>
  <!-- Outer Row -->
  <div class="row justify-content-center">
    <div class="col-xl-10 col-lg-12 col-md-9">
      <div class="card o-hidden border-0 shadow-lg my-5">
        <div class="card-body p-0">
          <!-- Nested Row within Card Body -->
          <div class="row">
            <div class="col-lg-6 offset-3">
              <div class="p-5">
                <div class="text-center">
                  <h1 class="h4 text-gray-900 mb-4">Welcome Back!</h1>
                </div>
                <form @submit="formSubmit" class="user">
                  <div class="form-group">
                    <input
                      type="text"
                      v-model="email"
                      class="form-control form-control-user"
                      :class="{ 'is-invalid': v$.email.$error }"
                      id="exampleInputEmail"
                      aria-describedby="emailHelp"
                      placeholder="Enter Email Address..."
                    />
                    <span class="text-danger" v-if="v$.email.$error">{{
                      v$.email.$errors[0].$message
                    }}</span>
                  </div>
                  <div class="form-group">
                    <input
                      v-model="password"
                      type="password"
                      class="form-control form-control-user"
                      :class="{ 'is-invalid': v$.password.$error }"
                      id="exampleInputPassword"
                      placeholder="Password"
                    />
                    <span class="text-danger" v-if="v$.password.$error">
                      <p
                        v-for="validationError in v$.password.$errors"
                        :key="validationError"
                      >
                        {{ validationError.$message }}
                      </p>
                    </span>
                  </div>
                  <div class="form-group">
                    <div class="custom-control custom-checkbox small">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck"
                      />
                      <label class="custom-control-label" for="customCheck"
                        >Remember Me</label
                      >
                    </div>
                  </div>
                  <button class="btn btn-primary btn-user btn-block">
                    Login
                  </button>
                  <hr />
                </form>
                <div class="text-center">
                  <a class="small" href="forgot-password.html"
                    >Forgot Password?</a
                  >
                </div>
                <div class="text-center">
                  <a class="small" href="register.html">Create an Account!</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required, minLength, email } from "@vuelidate/validators";
import axios from "axios";
export default {
  data() {
    return {
      v$: useValidate(),
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      email: "ua@rezosystems.com",
      password: "Rezo2221",
    };
  },
  validations() {
    return {
      email: { required, minLength: minLength(3), email },
      password: { required, minLength: minLength(3) },
    };
  },
  methods: {
    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors");
      } else {
        var data = {
          email: this.email,
          password: this.password,
        };
        var headers = {  };
         axios
          .post(this.env_api_url + "/login", data, { headers: headers })
          .then((response) => {
            localStorage.setItem("rezo_customers_user", response.data.token);
            this.$router.push({ name: "Dashboard" });
          });
      }
    },
  },
};
</script>

<style>
</style>