<template>
  <form @submit="formSubmit" class="user">
    <div class="form-group row">
      <div class="col-sm-6 mb-3 mb-sm-0">
        <input
          v-model="firstName"
          type="text"
          class="form-control form-control-user"
          :class="{ 'is-invalid': v$.firstName.$error }"
          id="exampleFirstName"
          placeholder="First Name"
        />
        <span class="text-danger" v-if="v$.firstName.$error">
          <span
            class="help-block"
            v-for="validationError in v$.firstName.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      </div>
      <div class="col-sm-6">
        <input
          v-model="lastName"
          type="text"
          class="form-control form-control-user"
          :class="{ 'is-invalid': v$.lastName.$error }"
          id="exampleLastName"
          placeholder="Last Name"
        />
        <span class="text-danger" v-if="v$.lastName.$error">
          <span
            class="help-block"
            v-for="validationError in v$.lastName.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      </div>
    </div>
    <div class="form-group">
      <input
        v-model="email"
        type="email"
        class="form-control form-control-user"
        :class="{ 'is-invalid': v$.email.$error }"
        id="exampleInputEmail"
        placeholder="Email Address"
      />
      <span class="info-block text-danger" v-if="v$.email.$error">
        <span
          class="help-block"
          v-for="validationError in v$.email.$errors"
          :key="validationError"
        >
          {{ validationError.$message }}
        </span>
      </span>
    </div>
    <div class="form-group row">
      <div class="col-sm-6 mb-3 mb-sm-0">
        <input
          v-model="password"
          type="password"
          class="form-control form-control-user"
          :class="{ 'is-invalid': v$.password.$error }"
          id="exampleInputPassword"
          placeholder="Password"
        />
        <span class="text-danger" v-if="v$.password.$error">
          <span
            class="help-block"
            v-for="validationError in v$.password.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      </div>
      <div class="col-sm-6">
        <input
          v-model="confirmPassword"
          type="password"
          class="form-control form-control-user"
          :class="{ 'is-invalid': v$.confirmPassword.$error }"
          id="exampleRepeatPassword"
          placeholder="Repeat Password"
        />
        <span class="text-danger" v-if="v$.confirmPassword.$error">
          <span
            class="help-block"
            v-for="validationError in v$.confirmPassword.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      </div>
      <div class="col-md-6 mt-3">
        <textarea placeholder="Notes" v-model="notes" rows="10" class="form-control"></textarea>
      </div>
      <div class="col-sm-6 form-inline mt-3">
        <div class="form-group mr-2">
          <label class="mr-1">Active</label>
          <label class="switch">
            <input type="checkbox" v-model="active" />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="form-group mr-2">
          <label class="mr-1">Archive</label>
          <label class="switch">
            <input type="checkbox" v-model="is_archived" />
            <span class="slider round"></span>
          </label>
        </div>
      </div>
      

    </div>
    <button class="btn btn-primary btn-user btn-block">Submit</button>
    <hr />
  </form>
</template>

<script>
import useValidate from "@vuelidate/core";
import axios from "axios";

import {
  required,
  minLength,
  email,
  maxLength,
  sameAs,
} from "@vuelidate/validators";

export default {
  components: {},
  data() {
    return {
      v$: useValidate(),
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      customer_id: null,
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      confirmPassword: null,
      customers_url:process.env.VUE_APP_SERVICE_URL + '/customers',
    };
  },
  validations() {
    return {
      firstName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      lastName: { required, minLength: minLength(3), maxLength: maxLength(50) },
      email: {
        required,
        email,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      password: {
        required: !this.customer_id ? true : false, ///If not edit then Required apply
        minLength: minLength(6),
        maxLength: maxLength(50),
      },
      confirmPassword: {
        required: !this.customer_id ? true : false, ///If not edit then Required apply
        minLength: minLength(6),
        sameAs: this.password ? sameAs(this.password) : false,
        maxLength: maxLength(50),
      },
    };
  },
  created() {
    var customerId = this.$route.params.id;
    var headers = {
      Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
    };
    axios
      .get(this.env_api_url + "/customers/" + customerId, { headers })
      .then((response) => {
        console.log("Edit Customer Record: ", response.data.customer);
        this.customer_id = response.data.customer.id;
        this.firstName = response.data.customer.first_name;
        this.lastName = response.data.customer.last_name;
        this.email = response.data.customer.email;
        this.password = response.data.customer.password;
        this.notes = response.data.customer.notes;
        this.is_archived = response.data.customer.is_archived?true:false;
        this.active = response.data.customer.active?true:false;
      })
      .catch((error) => {
        console.warn("Your Error is :", error);
      });
  },
  methods: {
    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors");
      } else {
        var data = {
          user_id: this.$route.params.id,
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          password: this.password,
          active: this.active,
          is_archived: this.is_archived,
          // confirmPassword: this.confirmPassword,
        };
        // var headers= {"Authorization" : `Bearer 34252345`};
        // console.log("saving customer basic data",data);
        var headers = {
          Authorization:
            `Bearer ` + localStorage.getItem("rezo_customers_user"),
        };
        this.$notify({
          type: "success",
          title: "Success",
          text: "Saved Successfully!",
        });
        axios
          .post(this.customers_url + "/", data, {
            headers: headers,
          })
          .then((response) => {
            console.log("update customer basic data: ", response);
          })
          .catch((error) => {
            console.warn("Your Error is :", error);
          });
      }
    },
  },
};
</script>
