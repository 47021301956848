<template>
  <form @submit="formSubmit" class="user">
    <!--((( Row Start )))-->
    <div class="form-group row">
      <div class="col-sm-12">
        <input
          v-model="server.title"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': v$.server.title.$error }"
          id="examplecompanyName"
          placeholder="Title"
        />
        <span class="text-danger" v-if="v$.server.title.$error">
          <span
            class="help-block"
            v-for="validationError in v$.server.title.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      </div>
    </div>

    <div class="row">
            <div class="col-md-6 mb-3">
            <div class="">
                <select v-model="server.server_id" class="form-control form-control-sm">
                <option selected :value="undefined">--Select--</option>
                <option :value="server.id" v-for="server in servers" :key="server">
                    {{ server.name }}
                </option>
                </select>
            </div>
            </div>
            <div class="col-md-6 mb-3">
            <input type="text" v-model="server.ip_address" placeholder="IP address" class="form-control form-control-sm">
            </div>
            <div class="col-md-6 mb-3">
            <input type="text" v-model="server.domain_name" placeholder="Domain name" class="form-control form-control-sm">
            </div>
            <div class="col-md-6 mb-3">
            <input type="text" v-model="server.product_name" placeholder="Product name" class="form-control form-control-sm">
            </div>
            <div class="col-md-8 mb-3">
            <input type="text" v-model="server.repo" placeholder="Repository link" class="form-control form-control-sm">
            </div>
            <div class="col-md-4">
            <input type="text" v-model="server.doc_root" placeholder="Document root" class="form-control form-control-sm">
            </div>  
            <div class="col-md-3 mb-3">
            <input type="text" v-model="server.db_name" placeholder="DB Name" class="form-control form-control-sm">
            </div>
            <div class="col-md-3 mb-3">
            <input type="text" v-model="server.db_user" placeholder="DB User" class="form-control form-control-sm">
            </div>
            <div class="col-md-3 mb-3">
            <input type="text" v-model="server.db_password" placeholder="DB Password" class="form-control form-control-sm">
            </div>
            <div class="col-md-3 mb-3">
            <input type="text" v-model="server.php_my_admin" placeholder="Phpmyadmin" class="form-control form-control-sm">
            </div>
            <div class="col-md-12 mb-3">
            <label for="">Notes</label>
            <textarea
                v-model="server.notes"
                class="form-control"
                cols="20"
                rows="2"
            ></textarea>
            </div>
        </div>
    

    
    <button class="btn btn-primary btn-user btn-block">{{server.id?"Update":"Submit"}}</button>
    <hr />
  </form>
</template>

<script>
import useValidate from "@vuelidate/core";

import { required, minLength,integer, maxLength } from "@vuelidate/validators";

import axios from "axios";

export default {
  components: {},
  props: ["editServer"],
  data() {
    return {
      v$: useValidate(),
      server: {},
    };
  },
  watch: {
    editServer: {
      immediate: true,
      handler (newVal, oldVal) {
        console.log(oldVal);
        this.server = newVal;
      }
    },
  },
  validations: {
    server: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      server_ip: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      ssh_login: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      ssh_password: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      display_order: {
        integer,
      },
    },
  },
  methods: {
    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors", this.v$.$error);
      } else {
        var data = this.server;
        data.module_type = 1;
        console.log("data submitting", data);
        var headers = {
          Authorization:
            `Bearer ` + localStorage.getItem("rezo_customers_user"),
        };
        var message = "Saved Successfully!"
        var type = "success"
        if(data.id){
          message = "Updated Successfully!"
          type = "info"
        }
        this.$notify({
          type: type,
          title: "Success",
          text: message,
        });
        axios
          .post("http://127.0.0.1:8000/api/rezo_modules", data, { headers })
          .then((response) => {
            if (response.status === 200) {
              this.server= {};
              this.v$.$reset();
              this.$emit("eventGetRezoModuleMethod")
            }
            
          });
      }
    },
  },
};
</script>
