<template>
  <form @submit="formSubmit" class="user">
    <div class="row">
      <div class="col-md-5">
        <label >Notes</label>
        <textarea
            v-model="customer.notes"
            class="form-control"
            name=""
            id=""
            cols="30"
            rows="5"
            ></textarea>
        <button :disabled="!customer.notes" class="btn btn-primary btn-user mt-2 btn-block">Submit</button>
      </div>
      
    </div>
    
    
    <hr />
  </form>
</template>

<script>

import axios from 'axios'




export default {
  components: {   },
  data() {
    return {
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      customer:{
          notes:null
      }
    };
  },
  created(){
    var customerId = this.$route.params.id;
    axios.get('https://fakestoreapi.com/users/'+customerId)
            .then((res)=>{
              this.customer = res.data;
            })
            .then((res)=>{
              console.log("Single Customer error",res);
              
            })
  },
  methods: {
    formSubmit() {
      var data={
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            password: this.password,
            confirmPassword: this.confirmPassword,
        };
        var headers= {"Authorization" : `Bearer 34252345`};
        console.log("saving customer basic data",data);
        axios
        .post('https://fakestoreapi.com/products',data, {headers:headers})
        .then(response => {
            console.log("update customer basic data: ",response);
        })
    },
  },
};
</script>
