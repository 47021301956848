import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Auth/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Customers from "../views/Customers/Customers.vue"
import NewCustomer from "../views/Customers/Create.vue"
import EditCustomer from "../views/Customers/EditCustomer.vue"
import Leads from "../views/Leads/Leads.vue"
import NewLead from "../views/Leads/Create.vue"
import EditLead from "../views/Leads/EditLead.vue"

import Admins from "../views/Admins/Admins.vue"
import NewAdmin from "../views/Admins/Create.vue"
import EditAdmin from "../views/Admins/EditAdmin.vue"

import Basket from '../views/Basket.vue'
import Servers from '../views/servers/Servers.vue'
import ServersEdit from '../views/servers/ServersEdit.vue'
import RezoModules from '../views/RezoModules/RezoModules'



const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: { 
      auth: false ,
      layout: 'login'
    },
    component: Login
  },
  {
    path: '/',
    name: 'Dashboard',
    meta: { auth: true },
    component: Dashboard
  },
// =============================================================================
//    Customers Routes
// =============================================================================
  
  {
    path:"/customers",
    name:"Customers",
    meta: { auth: true },
    component:Customers
  },
  {
    path:"/customers/create",
    name:"NewCustomer",
    meta: { auth: true },
    component:NewCustomer
  },
  {
    path:"/customers/:id/edit",
    name:"EditCustomer",
    meta: { auth: true },
    component:EditCustomer
  },
  {
    path:"/leads/:id/edit",
    name:"EditLead",
    meta: { auth: true },
    component:EditLead
  },
  
  {
    path: '/basket',
    name: 'Basket',
    meta: { auth: true },
    component: Basket
  },
// =============================================================================
//    Servers Routes
// =============================================================================
  
{
  path:"/servers",
  name:"Servers",
  meta: { auth: true },
  component:Servers
},
{
  path:"/servers/create",
  name:"NewServer",
  meta: { auth: true },
  component:ServersEdit
},
{
  path:"/servers/:id/edit",
  name:"EditServer",
  meta: { auth: true },
  component:Servers
},

// =============================================================================
//    Rezo Module Routes
// =============================================================================
  
{
  path:"/rezo_modules",
  name:"RezoModules",
  meta: { auth: true },
  component:RezoModules
},


  
// =============================================================================
//    Leads Routes
// =============================================================================
  
  {
    path:"/leads",
    name:"Leads",
    meta: { auth: true },
    component:Leads
  },
  {
    path:"/leads/create",
    name:"NewLead",
    meta: { auth: true },
    component:NewLead
  },

// =============================================================================
//    Admin Routes
// =============================================================================
  
{
  path:"/admins",
  name:"Admins",
  meta: { auth: true },
  component:Admins
},
{
  path:"/admins/create",
  name:"NewAdmin",
  meta: { auth: true },
  component:NewAdmin
},
{
  path:"/admins/:id/edit",
  name:"EditAdmin",
  meta: { auth: true },
  component:EditAdmin
},

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  var isloggedin= localStorage.getItem("rezo_customers_user");
  if (to.matched.some(record => record.meta.auth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isloggedin) {
      next({ name: 'Login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router
