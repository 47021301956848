<template>
  <!-- Page Heading -->

  <page-header
    :title="'Servers'"
    :hasLink="false"
    :linkTo="'/servers/create'"
    :linkText="'Add New Server'"
  />

  <!-- DataTales Example -->
  <div class="row">
    <div class="col-md-8">
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">List</h6>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table
              class="table table-bordered"
              id="dataTable"
              width="100%"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>IP</th>
                  
                  <th>Created At</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="server in servers" :key="server">
                  <td>{{ server.name }}</td>
                  <td>{{ server.server_ip }}</td>
                  
                  <td>{{ server.created_at }}</td>
                  <td>
                    
                    <button
                      v-on:click="editServer(server)"
                      class="btn btn-circle btn-primary btn-sm ml-1"
                    >
                      <i class="fas fa-edit"></i>
                    </button>

                    <button
                      v-on:click="deleteServer(server.id)"
                      class="btn btn-circle btn-danger btn-sm ml-1"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr></tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">{{server.id?"Edit":"Add"}} Server</h6>
        </div>
        <div class="card-body">
          
          <servers-edit :editServer="server" @eventGetServersMethod="getServers" />
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import PageHeader from "../SharedComponents/PageHeader.vue";
import axios from "axios";

import ServersEdit from './ServersEdit.vue';

export default {
  components: { PageHeader, ServersEdit },
  data() {
    return {
      server:{
        
      },
      servers: [],
      env_api_url: process.env.VUE_APP_SERVICE_URL
    };
  },

  methods: {
    getServers: function () {
      
      var type = this.$route.query.type;
      console.log("type", type);
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .get(this.env_api_url + "/servers?type=" + type, { headers })
        .then((response) => {
          this.servers = response.data;
        });
    },
    editServer: function(server){
      this.server = server;
    },
    deleteServer: function (serverId) {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      if (confirm("Do you really want to delete?")) {
        axios
          .delete(this.env_api_url + "/servers/" + serverId, {
            headers: headers,
          })
          .then((response) => {
            this.getServers();
            this.$notify({
              type: "success",
              title: "Success",
              text: "Deleted Successfully!",
            });
            console.log("Delete getServers basic data: ", response);
          })
          .catch((error) => {
            console.warn("Your Error is :", error);
          });
      }
    },
  },
  created() {
    this.getServers();
  },
};
</script>
