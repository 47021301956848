<template>
  <!-- Page Heading -->

  <page-header
    :title="'Admins'"
    :hasLink="true"
    :linkTo="'/admins/create'"
    :linkText="'New Admin'"
  />
  
  <!-- DataTales Example -->
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-primary">Records</h6>
    </div>
    <div class="card-body">
      <!-- <form class="form-inline">
        <div class="form-group">
          <input
            v-model="filters.name"
            class="form-control"
            placeholder="Name"
          />
        </div>

        <div class="form-group ml-1">
          <input
            v-model="filters.email"
            class="form-control"
            placeholder="Email"
          />
        </div>


        <div class="form-group ml-1">
          <button type="button" class="btn btn-danger" @click="this.filters={}">Reset</button>
        </div>
      </form> -->
      <div class="table-responsive">
        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0" >
          <thead>
            <tr>
              <th>Admin</th>
              <th>Email</th>
              <!-- <th>Type</th> -->
              <th>Actions</th>
            </tr>
          </thead>
          
          <tbody>
            <tr v-for="admin in admins" :key="admin">
              <td>{{admin.first_name}} {{admin.last_name}}</td>
              <td>{{admin.email}}</td>
              
              <td>
                <router-link :to="{name:'EditAdmin', params: { id:admin.id}}" >
                  <i class="fas zoom fa-edit"></i>
                </router-link >

                <i v-on:click="deleteAdmin(admin.id)" style="color:red;" class="fas ml-1 zoom fa-trash"></i>
              
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../SharedComponents/PageHeader.vue";
import axios from "axios";
export default {
  components: { PageHeader },
  data() {
    return {
      filters:{
        
      },
      admins: [],
      env_api_url: process.env.VUE_APP_SERVICE_URL,
    };
  },
  watch: {
      filters: {
        deep: true,
        handler () {
          if (!this.awaitingSearch) {
            setTimeout(() => {
              this.getAdmins()
              this.awaitingSearch = false;
            }, 700); // 1 sec delay
          }
          this.awaitingSearch = true;
        }
      },
    },
  methods: {
    getAdmins: function ()
    {  
      this.filters.type = this.$route.query.type?this.$route.query.type:"";
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      
      axios
        .get(this.env_api_url + "/admins", { headers })
        .then((response) => {
          console.log("ADMINS ",response);
          this.admins = response.data;
        })
        .catch((error) => {
          console.warn("Your Error is :", error);
        });
    },

    deleteAdmin: function (adminId) {
       
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      if (confirm("Do you really want to delete?")) {
        axios
        .delete(this.env_api_url + "/admins/" + adminId, {
          headers: headers,
        })
        .then((response) => {
          this.getAdmins();
          this.$notify({
            type: "success",
            title: "Success",
            text: "Deleted Successfully!",
          });
          console.log("Deleted Admin: ", response);
        })
        .catch((error) => {
          console.warn("Your Error is :", error);
        });
      }
    },
  },
  created() {
    this.getAdmins();
  },
};
</script>
<style scoped>
  .zoom {
    transition: transform .2s;
  }
  .zoom:hover {
    transform: scale(1.5);
  }
</style>
